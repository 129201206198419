var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline d-flex align-center" },
    [
      _c(
        "v-btn",
        { attrs: { text: "" }, on: { click: _vm.goToDashboard } },
        [_c("v-icon", { attrs: { color: "primary" } }, [_vm._v("home")])],
        1
      ),
      _c("v-icon", [_vm._v("keyboard_arrow_right")]),
      _vm.showOrgMenu === true
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "", "close-on-content-click": false },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "flex-shrink-1",
                              attrs: {
                                disabled: _vm.fetchingSpaces,
                                text: "",
                                color: "primary"
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                  },
                                  [_vm._v(_vm._s(_vm.organizationName))]
                                ),
                                _c("v-icon", [_vm._v("arrow_drop_down")])
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                57603363
              ),
              model: {
                value: _vm.organizationMenu,
                callback: function($$v) {
                  _vm.organizationMenu = $$v
                },
                expression: "organizationMenu"
              }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "overflow-y-auto",
                  staticStyle: { "max-height": "500px" },
                  attrs: { nav: "", dense: "" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-text-field", {
                        staticClass: "ma-0 pa-0",
                        attrs: {
                          autofocus: "",
                          "append-icon": "search",
                          label: "Filter",
                          dense: "",
                          "single-line": ""
                        },
                        model: {
                          value: _vm.organizationSearch,
                          callback: function($$v) {
                            _vm.organizationSearch = $$v
                          },
                          expression: "organizationSearch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { disabled: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "primary--text caption text-uppercase d-flex align-center"
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("apartment")]
                              ),
                              _vm._v("Organizations ")
                            ],
                            1
                          ),
                          _c("v-divider")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.sortedOrgNames, function(item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.long_id,
                        on: {
                          click: function($event) {
                            return _vm.fetchOrgSpaces(item.long_id)
                          }
                        }
                      },
                      [
                        _c("v-list-item-title", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _vm._v(" " + _vm._s(item.long_id) + " "),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    outlined: "",
                                    color: "grey lighten-1",
                                    "x-small": ""
                                  }
                                },
                                [_vm._v(_vm._s(item.role))]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-menu",
        {
          attrs: {
            disabled: _vm.fetchingSpaces,
            "offset-y": "",
            "offset-x": "",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { small: "", icon: "", text: "" } }, on),
                    [_c("v-icon", [_vm._v("more_horiz")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "overflow-y-scroll",
              staticStyle: { "max-height": "500px", "max-width": "70vw" },
              attrs: { accordion: "", focusable: "" }
            },
            [
              _c(
                "v-expansion-panel",
                { staticStyle: { "min-width": "70vw", "max-width": "70vw" } },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "caption primary--text font-weight-bold" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("dashboard")]
                          ),
                          _vm._v(" Spaces ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-list-item-group", [
                        _vm.educationSpaces.length > 0
                          ? _c(
                              "div",
                              { staticStyle: { "max-width": "70vw" } },
                              [
                                _c(
                                  "v-list-item",
                                  { attrs: { disabled: "" } },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "primary--text caption text-uppercase"
                                          },
                                          [_vm._v("courses")]
                                        ),
                                        _c("v-divider")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(_vm.educationSpaces, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.sid,
                                      on: {
                                        click: function($event) {
                                          return _vm.openSpace(
                                            item.space_long_id,
                                            item.sid,
                                            item.iid,
                                            item.snid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between align-center"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.space_long_id)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  outlined: "",
                                                  color: "grey lighten-1",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.spaceRole(
                                                      item.space_role
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.researchSpaces.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "mt-5",
                                    attrs: { disabled: "" }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "primary--text caption text-uppercase"
                                          },
                                          [_vm._v("Research Projects")]
                                        ),
                                        _c("v-divider")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(_vm.researchSpaces, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.sid,
                                      on: {
                                        click: function($event) {
                                          return _vm.openSpace(
                                            item.space_long_id,
                                            item.sid,
                                            item.iid,
                                            item.snid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between align-center"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.space_long_id)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  outlined: "",
                                                  color: "grey lighten-1",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.spaceRole(
                                                      item.space_role
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.datasetSpaces.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "mt-5",
                                    attrs: { disabled: "" }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "primary--text caption text-uppercase"
                                          },
                                          [_vm._v("Datasets")]
                                        ),
                                        _c("v-divider")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(_vm.datasetSpaces, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.sid,
                                      on: {
                                        click: function($event) {
                                          return _vm.openSpace(
                                            item.space_long_id,
                                            item.sid,
                                            item.iid,
                                            item.snid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between align-center"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.space_long_id)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  outlined: "",
                                                  color: "grey lighten-1",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.spaceRole(
                                                      item.space_role
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.sortedInstances.length
                ? _c(
                    "v-expansion-panel",
                    {
                      staticStyle: { "min-width": "70vw", "max-width": "70vw" }
                    },
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          staticClass: "caption primary--text font-weight-bold"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("group")]
                              ),
                              _vm._v(" Instances ")
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-list-item-group", [
                            _c(
                              "div",
                              { staticStyle: { "max-width": "70vw" } },
                              [
                                _c(
                                  "v-list-item",
                                  { attrs: { disabled: "" } },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "primary--text caption text-uppercase"
                                          },
                                          [_vm._v("instances")]
                                        ),
                                        _c("v-divider")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(_vm.sortedInstances, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.iid,
                                      attrs: {
                                        disabled: _vm.disabledInstance(
                                          item.instance_name,
                                          item.data
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openInstance(
                                            item.instance_name,
                                            item.iid,
                                            item.role
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between align-center"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.instance_name)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  outlined: "",
                                                  color: "grey lighten-1",
                                                  "x-small": ""
                                                }
                                              },
                                              [_vm._v(_vm._s(item.role))]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-expansion-panel",
                { staticStyle: { "min-width": "70vw", "max-width": "70vw" } },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "caption primary--text font-weight-bold" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("layers")]
                          ),
                          _vm._v(" Snapshots ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "overflow-y-auto",
                          staticStyle: { "max-height": "500px" },
                          attrs: { nav: "", dense: "" },
                          model: {
                            value: _vm.snapshot,
                            callback: function($$v) {
                              _vm.snapshot = $$v
                            },
                            expression: "snapshot"
                          }
                        },
                        [
                          _vm.currentState.length &&
                          _vm.isInstanceEditor === true &&
                          _vm.isDistributedInstance === false
                            ? _c(
                                "v-list-item",
                                { attrs: { disabled: "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "primary--text caption text-uppercase"
                                        },
                                        [
                                          _vm._v(" mutable state "),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentState.length &&
                          _vm.isInstanceEditor === true &&
                          _vm.isDistributedInstance === false
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.openSnapshot(
                                        _vm.currentState[0].long_id,
                                        _vm.currentState[0].snid
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _c("span", { staticClass: "caption" }, [
                                      _vm._v(
                                        _vm._s(_vm.currentState[0].long_id)
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.sortedSnapshots.length
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "primary--text caption text-uppercase d-flex align-center flex-wrap"
                                        },
                                        [
                                          _vm._v(" immutable states "),
                                          _c("v-spacer"),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                color: "info",
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.goToSnapshotTimelineView()
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("timeline")
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2773548375
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Timeline view of snapshots"
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.sortedSnapshots.length
                            ? _c(
                                "div",
                                _vm._l(_vm.sortedSnapshots, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.snid,
                                      on: {
                                        click: function($event) {
                                          return _vm.openSnapshot(
                                            item.long_id,
                                            item.snid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(item.long_id))
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm.currentSpaceType !==
                                _vm.spaceTypes.VENDOR_SPACE &&
                              _vm.isDevelopment === true &&
                              _vm.isInstanceEditor === true
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.addSnapshot(
                                        _vm.snapshotTypes.QUICK_SNAPSHOT
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "subtitle-2 d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v("mdi-camera-plus-outline")]
                                      ),
                                      _vm._v("Quick snapshot")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color:
                                                            "grey lighten-1"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("mdi-information")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "This will create and save a snapshot of your current state, including all your files, tables, and applications."
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }