<template>
    <div class="d-inline d-flex align-center">
        <v-btn @click="goToDashboard" text>
            <v-icon color="primary">home</v-icon>
        </v-btn>
        <v-icon>keyboard_arrow_right</v-icon>
        <v-menu v-model="organizationMenu" offset-y v-if="showOrgMenu === true" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
                <v-btn :disabled="fetchingSpaces" v-on="on" text color="primary" class="flex-shrink-1">
                    <div class="d-flex align-center">
                        <div class="primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ organizationName }}</div>
                        <v-icon>arrow_drop_down</v-icon>
                    </div>
                </v-btn>
            </template>
            <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                <v-list-item>
                    <v-text-field autofocus v-model="organizationSearch" class="ma-0 pa-0" append-icon="search" label="Filter" dense single-line></v-text-field>
                </v-list-item>
                <v-list-item disabled>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text caption text-uppercase d-flex align-center">
                            <v-icon small class="mr-1">apartment</v-icon>Organizations
                        </v-list-item-title>
                        <v-divider></v-divider>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="item in sortedOrgNames" :key="item.long_id" @click="fetchOrgSpaces(item.long_id)">
                    <v-list-item-title>
                        <div class="d-flex justify-space-between align-center">
                            {{ item.long_id }}
                            <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ item.role }}</v-chip>
                        </div>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-menu :disabled="fetchingSpaces" offset-y offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon text>
                    <v-icon>more_horiz</v-icon>
                </v-btn>
            </template>
            <v-expansion-panels style="max-height:500px;max-width:70vw" class="overflow-y-scroll" accordion focusable>
                <v-expansion-panel style="min-width:70vw; max-width:70vw">
                    <v-expansion-panel-header class="caption primary--text font-weight-bold">
                        <div class="d-flex justify-start">
                            <v-icon class="mr-1" small>dashboard</v-icon>
                            Spaces
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list-item-group>
                            <div style="max-width:70vw" v-if="educationSpaces.length > 0">
                                <v-list-item disabled>
                                    <v-list-item-content>
                                        <v-list-item-title class="primary--text caption text-uppercase">courses</v-list-item-title>
                                        <v-divider></v-divider>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in educationSpaces"
                                    :key="item.sid"
                                    @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)"
                                >
                                    <v-list-item-title>
                                        <div class="d-flex justify-space-between align-center">
                                            <span class="caption">{{ item.space_long_id }}</span>
                                            <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                            </div>
                            <div v-if="researchSpaces.length > 0">
                                <v-list-item disabled class="mt-5">
                                    <v-list-item-content>
                                        <v-list-item-title class="primary--text caption text-uppercase">Research Projects</v-list-item-title>
                                        <v-divider></v-divider>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in researchSpaces"
                                    :key="item.sid"
                                    @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)"
                                >
                                    <v-list-item-title>
                                        <div class="d-flex justify-space-between align-center">
                                            <span class="caption">{{ item.space_long_id }}</span>
                                            <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                            </div>
                            <div v-if="datasetSpaces.length > 0">
                                <v-list-item disabled class="mt-5">
                                    <v-list-item-content>
                                        <v-list-item-title class="primary--text caption text-uppercase">Datasets</v-list-item-title>
                                        <v-divider></v-divider>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in datasetSpaces"
                                    :key="item.sid"
                                    @click="openSpace(item.space_long_id, item.sid, item.iid, item.snid)"
                                >
                                    <v-list-item-title>
                                        <div class="d-flex justify-space-between align-center">
                                            <span class="caption">{{ item.space_long_id }}</span>
                                            <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ spaceRole(item.space_role) }}</v-chip>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                            </div>
                        </v-list-item-group>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="sortedInstances.length" style="min-width:70vw; max-width:70vw">
                    <v-expansion-panel-header class="caption primary--text font-weight-bold">
                        <div class="d-flex justify-start">
                            <v-icon class="mr-1" small>group</v-icon>
                            Instances
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list-item-group>
                            <div style="max-width:70vw">
                                <v-list-item disabled>
                                    <v-list-item-content>
                                        <v-list-item-title class="primary--text caption text-uppercase">instances</v-list-item-title>
                                        <v-divider></v-divider>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in sortedInstances"
                                    :key="item.iid"
                                    @click="openInstance(item.instance_name, item.iid, item.role)"
                                    :disabled="disabledInstance(item.instance_name, item.data)"
                                >
                                    <v-list-item-title>
                                        <div class="d-flex justify-space-between align-center">
                                            <span class="caption">{{ item.instance_name }}</span>
                                            <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ item.role }}</v-chip>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                            </div>
                        </v-list-item-group>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel style="min-width:70vw; max-width:70vw">
                    <v-expansion-panel-header class="caption primary--text font-weight-bold">
                        <div class="d-flex justify-start">
                            <v-icon class="mr-1" small>layers</v-icon>
                            Snapshots
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list style="max-height:500px" class="overflow-y-auto" nav dense v-model="snapshot">
                            <v-list-item disabled v-if="currentState.length && isInstanceEditor === true && isDistributedInstance === false">
                                <v-list-item-content>
                                    <v-list-item-title class="primary--text caption text-uppercase">
                                        mutable state
                                        <v-spacer></v-spacer>
                                    </v-list-item-title>
                                    <v-divider></v-divider>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="currentState.length && isInstanceEditor === true && isDistributedInstance === false"
                                @click="openSnapshot(currentState[0].long_id, currentState[0].snid)"
                            >
                                <v-list-item-title
                                    ><span class="caption">{{ currentState[0].long_id }}</span></v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item v-if="sortedSnapshots.length">
                                <v-list-item-content>
                                    <v-list-item-title class="primary--text caption text-uppercase d-flex align-center flex-wrap">
                                        immutable states
                                        <v-spacer></v-spacer>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" small color="info" icon @click="goToSnapshotTimelineView()">
                                                    <v-icon>timeline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Timeline view of snapshots</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                    <v-divider></v-divider>
                                </v-list-item-content>
                            </v-list-item>
                            <div v-if="sortedSnapshots.length">
                                <v-list-item v-for="item in sortedSnapshots" :key="item.snid" @click="openSnapshot(item.long_id, item.snid)">
                                    <v-list-item-title>
                                        <span class="caption">{{ item.long_id }}</span></v-list-item-title
                                    >
                                </v-list-item>
                            </div>
                            <v-list-item
                                v-else-if="currentSpaceType !== spaceTypes.VENDOR_SPACE && isDevelopment === true && isInstanceEditor === true"
                                @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)"
                            >
                                <v-list-item-title class="subtitle-2 d-flex align-center"
                                    ><v-icon small class="mr-1">mdi-camera-plus-outline</v-icon>Quick snapshot</v-list-item-title
                                >
                                <v-list-item-action>
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                        </template>
                                        <span
                                            >This will create and save a snapshot of your current state, including all your files, tables, and
                                            applications.</span
                                        >
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-menu>
    </div>
</template>
<script>
import { breadcrumbs } from '@/mixins/breadcrumbs'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import { mapGetters } from 'vuex'

export default {
    name: 'BreadcrumbsSmallScreen',
    mixins: [breadcrumbs, enumsData, createQuickAndDetailedSnapshots, snapshotEnums],
    computed: {
        ...mapGetters('spaceStore', ['currentSpaceType']),
        ...mapGetters('instanceStore', ['isInstanceEditor'])
    }
}
</script>
